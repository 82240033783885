/*---------------------------------------------------------------
 |                  【数据中台专用】请求接口封装                  |
 ---------------------------------------------------------------*/
import axios from 'axios'
import curry from './curry'

axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
//先暂时这么用吧
// let baseURL = 'http://bipaas.elinkservice.cn/get'
//线上地址外网可访问
let baseURL = 'http://taidong-api.elinkservice.cn/get'
//联调
// let baseURL = 'http://192.168.1.59:18080/get'
const request = curry((method, uri, data = {}, params = {}) => {
    return new Promise(reslove => {
        let returnIniData = {
            data: {},
            msg: '',
            code: ''
        }

        const { headers } = params

        // env文件配置接口
        let url = baseURL

        const succFn = res => {
            // log(`[request成功] ${url}`, data, res);
            // console.log(res);
            let retData = {
                ...returnIniData,
                ...res.data
            }
            // if(typeof Vue.$afterRequestHook == 'function'){
            // 	retData = Vue.$afterRequestHook(retData);
            // }
            if (res.data.code > 8000 && res.data.code < 10000) {
                // Message.error(res.data.msg);
            }
            reslove(retData)
        }

        const failFn = err => {
            //  log(`[request失败] ${url}`, data, err);
            console.log(err)

            reslove(
                Object.assign({}, returnIniData, {
                    httpCode: '9999', //访问出现意外
                    msg: '网络错误'
                })
            )
        }

        if (method.toUpperCase() == 'POST') {
            axios
                .post(
                    url,
                    {
                        // apiCode: "/api/post",
                        // dispatcherSystem: "dataservice",
                        // param: {
                        // ds_code: uri,
                        // app_code: "empt",
                        // orderby: "[]",
                        ...data
                        // },
                    },
                    {
                        headers,
                        responseType: 'json'
                    }
                )
                .then(succFn)
                .catch(failFn)
        }
    })
})

export const requestPostBi = request('post')
