<template>
    <ek-panel title="星级商户分析">
        <div class="box">
            <div class="btn_box">
                <div></div>
                <!-- <ek-single-btn :list="btnList"
                               :index="0" /> -->
                <ek-segements :index="2" />
            </div>
            <section>
                <div class="chart">
                    <ek-chart :options="options"
                              :animation="true"
                              :animationData="options.series[0].data" />
                </div>
                <p>星级服务排行</p>
                <div class="table">
                    <div class="table_header lucencys">
                        <span>序号</span>
                        <span>商户名称</span>
                        <span>星级</span>
                    </div>
                    <div class="t-list">
                        <swiper ref="orderSwiper"
                                :options="swiperOptions">
                            <swiper-slide v-for="(items, index) in tableData"
                                          :key="index">
                                <div :class="['item', index == 1 ? 'lucency' : '']"
                                     v-for="(item, index) in items"
                                     :key="index">
                                    <span>{{ item.num }}</span>
                                    <span>{{ item.name }}</span>
                                    <div class="strBox">
                                        <i v-for="itemL in Number(item.level)"
                                           :key="itemL"
                                           class="icon-font el-icon-star-on"></i>
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </section>
        </div>
    </ek-panel>
</template>

<script>
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            btnList: ['营业额', '服务能力', '整改情况', '其他'],
            // btnList: ['营业额'],
            options: {
                color: ['#2fc5f0', '#9115ff', '#cf284a', '#0ae0ac', '#ff9900'],
                title: {
                    text: '星级商户分析',
                    left: 'left',
                    top: '10%',
                    textStyle: {
                        color: '#fff',
                        fontSize: '18',
                    },
                },
                tooltip: {
                    trigger: 'item',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: params => {
                        let el = ''
                        el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}个</span>
                      </div>
                    </div>`
                        return el
                    }
                },
                legend: [
                    {
                        data: [],
                        orient: 'vertical',
                        left: '0',
                        top: '30%',
                        itemGap: 15,
                        textStyle: {
                            color: '#fff',
                            fontSize: 18,
                            rich: {
                                val: {
                                    fontSize: 25,
                                },
                            },
                        },
                    },
                    {
                        data: [],
                        orient: 'vertical',
                        top: '30%',
                        left: '15%',
                        itemGap: 15,
                        label: {
                            color: '#fff',
                            formatter: `{b|{b}} {d|{d}%}`,
                            rich: {
                                d: {
                                    color: '#2AFFEB',
                                    fontSize: 18,
                                },
                            },
                        },
                        textStyle: {
                            color: '#fff',
                            fontSize: 18,
                            rich: {
                                val: {
                                    fontSize: 25,
                                },
                            },
                        },
                    },
                ],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['70%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#4A4D4D',
                        },
                        emphasis: {
                            label: {
                                formatter: `{b} \n {d}%`,
                                show: true,
                                fontSize: 18,
                                color: '#fff',
                                borderWidth: 0,
                            },
                        },
                        labelLine: {
                            show: true,
                        },
                        data: [],
                    },
                ],
            },
            tableData: [],
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
        }
    },
    mounted() {
        this.getPeiList();
        this.getList();
    },
    methods: {
        getPeiList() {
            const par = {
                "ServiceMerchantAnalysises[]": {
                    "ServiceMerchantAnalysis": {
                        "@column": "ID,CATEGORY_NAME,CATEGORY_NUM"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let arr = res['ServiceMerchantAnalysises[]'].map((item, i, aboriginalArr) => ({
                            name: item.ServiceMerchantAnalysis.CATEGORY_NAME,
                            value: item.ServiceMerchantAnalysis.CATEGORY_NUM,
                        })).filter(f => {
                            return f.name != "TOTAL"
                        })
                        this.options.series[0].data = arr
                        let legend = arr.map((item) => item.name)
                        this.options.legend[0].data = legend.splice(0, 3)
                        this.options.legend[1].data = legend
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getList() {
            const par = {
                "ServiceMerchants[]": {
                    "ServiceMerchant": {
                        "@column": "id,place_name,star",
                        "@order": "star_flag-"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let tempList = res['ServiceMerchants[]'].map((item, index) => ({
                            num: index + 1,
                            name: item.ServiceMerchant.place_name,
                            level: item.ServiceMerchant.star == "五星" ? 5 : item.ServiceMerchant.star == "四星" ? 4 : item.ServiceMerchant.star == "三星" ? 3 : item.ServiceMerchant.star == "二星" ? 2 : 1
                        }))
                        this.tableData = this.groupList(tempList, 2)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        handleDate() { },
    },
}
</script>

<style scoped lang="scss">
.box {
    height: 500px - 30px;
    padding: 15px 20px;

    .btn_box {
        @include flex();
        justify-content: space-between;
    }
    .chart {
        height: 230px;
    }
    .table {
        @include flex(column);
        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }
        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 50px;
                line-height: 50px;
            }
            :nth-child(1) {
                width: 50px;
            }
            :nth-child(2) {
                flex: 1;
            }
            :nth-child(3) {
                width: 300px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 105px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                > :nth-child(1) {
                    width: 50px;
                }
                > :nth-child(2) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .strBox {
                    width: 300px;
                    color: #ffcd05;
                    // color: #e4a90b;
                    text-align: center;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
</style>
