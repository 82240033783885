<template>
    <ek-panel title="共建共治">
        <div class="box">
            <div class="barn">
                <el-carousel :interval="4000"
                             type="card">
                    <el-carousel-item v-for="(item, index) in imageList"
                                      :key="index">
                        <img :src="item.src"
                             alt="" />
                        <div class="title">{{ item.title }}</div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="chart">
                <div class="pie">
                    <section>
                        <span>活动数据统计分析</span>
                        <div>
                            <img src="../../../../../public/images/components/bdg/jqfw/hdtj.png"
                                 alt="" />
                            <div>
                                <span>{{ total }}<span>次</span> </span><span>总次数</span>
                            </div>
                        </div>
                    </section>
                    <ek-chart :options="optionsPie"
                              :animation="true"
                              :animationData="optionsPie.series[0].data" />
                </div>
                <div class="bar">
                    <ek-chart :options="optionsBar"
                              :animation="true"
                              :animationData="optionsBar.xAxis.data" />
                </div>
            </div>
            <div class="table">
                <p>活动参与数据分析</p>
                <div class="table_header lucencys">
                    <span>序号</span>
                    <span>活动名称</span>
                    <span>活动时间</span>
                    <!-- <span>参与商户数</span> -->
                    <span>参与人数</span>
                </div>
                <div class="t-list">
                    <swiper ref="orderSwiper"
                            :options="swiperOptions">
                        <swiper-slide v-for="(items, index) in tableData"
                                      :key="index">
                            <div :class="[
                  'item',
                  index == 1 ? 'lucency' : index == 3 ? 'lucency' : '',
                ]"
                                 v-for="(item, index) in items"
                                 :key="index">
                                <span>{{ item.ServiceJointConstActivity.id }}</span>
                                <span>{{ item.ServiceJointConstActivity.activity_title }}</span>
                                <span>{{ item.ServiceJointConstActivity.activity_date }}</span>
                                <!-- <span>{{ item.merchant_num }}</span> -->
                                <span>{{ item.ServiceJointConstActivity.activity_people }}</span>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </ek-panel>
</template>
  
  <script>
import { requestPostBi } from '@/utils/requestMiddleground'
import EkChart from '../../../../components/Global/components/EkChart.vue'
import * as echarts from 'echarts'

export default {
    components: { EkChart },
    data() {
        return {
            items: [],
            imageList: [
                // {
                //     src: require('../../../../../public/images/components/bdg/jqfw/barn1.png'),
                //     title: '为提升台东商圈商业街进一步发展，特联合符合此次活动的...',
                // },
                // {
                //     src: require('../../../../../public/images/components/bdg/jqfw/barn2.png'),
                //     title: '台东社区开展“社区联动 繁荣商圈 社区文化艺术节”...',
                // },
                // {
                //     src: require('../../../../../public/images/components/bdg/jqfw/barn3.png'),
                //     title: '举办“社区+商圈”文明四季行暨“红领暖蜂”计...',
                // },
            ],
            tableData: [],
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
            total: '',
            optionsBar: {
                color: ['#0CCE70', '#EF9C00'],
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: (params) => {
                        // console.log(params)
                        // let { holidayVal, dateVal } = this.formData
                        // let unit = holidayVal ? '' : dateVal == 0 ? '时' : ''
                        let el = ''
                        params.map((item) => {
                            el = el.concat(
                                `<div class="text" style="font-size:18px;margin-top:5px;">
                        <div class="left">
                          <span>${item.marker}</span> 
                          <span class="t">${item.seriesName
                                }&nbsp&nbsp&nbsp</span> 
                        </div>
                        <div class="right">
                          <span class="v">${item.value +
                                (item.seriesName == '人员' ? '人' : '次')
                                }</span>  
                        </div>
                      </div>`
                            )
                        })
                        return `<div class="tooltip-box column">
                      <span class="b-t">${params[0].name}</span>
                      ${el}
                    </div>`
                    },
                },
                title: {
                    left: 'center',
                    text: '参与活动次数排名',
                    textStyle: {
                        color: '#fff',
                        fontSize: 18,
                    },
                },
                legend: {
                    left: 'center',
                    icon: 'rect',
                    top: '7%',
                    itemWidth: 14,
                    itemHeight: 14,
                    textStyle: {
                        color: '#fff',
                        fontSize: 18,
                        padding: [0, 10],
                    },
                },
                grid: {
                    top: '15%',
                    right: '10%',
                },
                xAxis: {
                    type: 'category',
                    // 不显示刻度线
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        // 网格线为虚线
                        show: false,
                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize: 18,
                        rotate: 38

                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#2973AC',
                        },
                    },
                    data: [],
                },
                yAxis: [
                    {
                        type: 'value',
                        name: '人员',
                        min: 0,
                        max: 250,
                        interval: 50,
                        splitLine: {
                            show: false,
                        },
                    },
                    // {
                    //   type: 'value',
                    //   name: '商户',
                    //   splitLine: {
                    //     show: false,
                    //   },
                    // },
                ],
                series: [
                    {
                        name: '次数',
                        type: 'bar',
                        barWidth: '20%',
                        data: [],
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#E9AD0B',
                                },
                                {
                                    offset: 1,
                                    color: '#142D3F',
                                },
                            ]),
                        },
                    },
                    {
                        name: '人员',
                        type: 'bar',
                        barWidth: '20%',
                        data: [],
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#2FC5F0',
                                },
                                {
                                    offset: 1,
                                    color: '#142D3F',
                                },
                            ]),
                        },
                    },
                ],
            },
            optionsPie: {
                tooltip: {
                    trigger: 'item',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: (params) => {
                        let el = ''
                        el = `<div class="tooltip-box row">
                      <div class="text" style="font-size:18px">
                        <span class="t">${params.name}：</span>
                        <span class="v">${params.value}次</span>
                      </div>
                    </div>`
                        return el
                    },
                },
                color: ['#2fc5f0', '#e9ad0b', '#0ae0ac'],
                legend: [],
                series: [
                    {
                        type: 'pie',
                        radius: ['30%', '50%'],
                        center: ['60%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center',
                        },
                        itemStyle: {
                            borderWidth: 2,
                            borderColor: '#4A4D4D',
                        },
                        emphasis: {
                            label: {
                                formatter: `{b} \n {d}%`,
                                show: true,
                                fontSize: 18,
                                borderWidth: 0,
                                color: '#fff',
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [],
                    },
                ],
            },
        }
    },
    mounted() {
        this.getPeiList()
        this.getBarList()
        this.getDown()
        this.getImageList()
    },
    methods: {
        getPeiList() {
            let par = {
                "ServiceActivityAnalysises[]": {
                    "ServiceActivityAnalysis": {
                        "@column": "ID,CATEGORY_NAME,CATEGORY_NUM"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        res['ServiceActivityAnalysises[]'].map(m => {
                            if (m.ServiceActivityAnalysis.CATEGORY_NAME && m.ServiceActivityAnalysis.CATEGORY_NAME == "TOTAL") {
                                this.total = m.ServiceActivityAnalysis.CATEGORY_NUM
                            }
                        })
                        this.optionsPie.series[0].data = res['ServiceActivityAnalysises[]'].map(
                            (item) => ({
                                name: item.ServiceActivityAnalysis.CATEGORY_NAME,
                                value: item.ServiceActivityAnalysis.CATEGORY_NUM,
                            })
                        ).filter(f => {
                            return f.name != "TOTAL"
                        })
                        this.optionsPie.legend = [
                            {
                                data: this.optionsPie.series[0].data
                                    .map((item) => item.name)
                                    .splice(0, 3),
                                type: '',
                                left: '5%',
                                top: '10%',
                                orient: 'vertical',
                                textStyle: {
                                    color: '#fff',
                                    fontSize: 18,
                                },
                            },
                        ]
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getBarList() {
            let par = {
                "ServiceJoinActivityAnalysises[]": {
                    "ServiceJoinActivityAnalysis": {
                        "@column": "id,community_name,join_times,join_num",
                        "@order": "join_times-"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let arr = [
                            ...res['ServiceJoinActivityAnalysises[]'],
                        ]
                        this.optionsBar.xAxis.data = arr
                            .map((item) => item.ServiceJoinActivityAnalysis.community_name)
                        this.optionsBar.series[0].data = arr
                            .map((item) => item.ServiceJoinActivityAnalysis.join_times)

                        this.optionsBar.series[1].data = arr
                            .map((item) => item.ServiceJoinActivityAnalysis.join_num)
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getDown() {
            let par = {
                "ServiceJointConstActivitys[]": {
                    "ServiceJointConstActivity": {
                        "@column": "id,activity_title,activity_date,activity_people"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let tempList = res['ServiceJointConstActivitys[]'].map((item, index) => ({
                            num: index + 1,
                            ...item,
                        }))
                        this.tableData = this.groupList(tempList, 4)
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getImageList() {
            let par = {
                "ServiceVolunteerActivitys[]": {
                    "ServiceVolunteerActivity": {
                        "@column": "id,title,img"
                    }
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        this.imageList = res['ServiceVolunteerActivitys[]'].map(m => {
                            return {
                                title: m.ServiceVolunteerActivity.title,
                                src: m.ServiceVolunteerActivity.img
                            }
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
}
</script>
  
  <style scoped lang="scss">
.box {
    @include flex(column);
    padding: 10px 30px;
    .barn {
        height: 241px;
        margin-top: 10px;
        img {
            width: 100%;
            height: 100%;
        }
        .title {
            position: absolute;
            bottom: 0px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 38px;
            width: 100%;
            line-height: 38px;
            background-color: rgb(31, 35, 50, 0.5);
            padding: 0 10px;
        }
        ::v-deep .el-carousel__item {
            @include flex(column);
        }
    }
    .chart {
        @include flex();
        height: 380px;
        margin-top: 20px;
        .bar {
            width: 60%;
        }
        .pie {
            width: 40%;
            section {
                @include flex(column);
                > div {
                    @include flex();
                    margin-top: 10px;
                    > div {
                        @include flex(column);
                        justify-content: space-between;
                        margin-left: 10px;
                        > :nth-child(1) {
                            font-size: 30px;
                            color: #2ef7f9;
                            font-weight: 600;
                            > span {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    .table {
        @include flex(column);
        margin-top: 20px;
        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }
        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 50px;
                line-height: 50px;
            }
            :nth-child(1) {
                width: 50px;
            }
            :nth-child(2) {
                flex: 1;
            }
            :nth-child(2) {
                width: 200px;
            }
            :nth-child(3) {
                width: 130px;
            }
            :nth-child(4) {
                width: 100px;
            }
            :nth-child(5) {
                width: 100px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 200px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                > :nth-child(1) {
                    width: 50px;
                }
                > :nth-child(2) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > :nth-child(2) {
                    width: 200px;
                }
                > :nth-child(3) {
                    width: 130px;
                }
                > :nth-child(4) {
                    width: 100px;
                }
                > :nth-child(5) {
                    width: 100px;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
::v-deep .el-carousel,
.el-carousel__container {
    display: flex;
    flex-direction: column;
    height: 251px;
}
::v-deep .el-carousel__button {
    width: 10px;
    height: 10px;
    border-radius: 10px;
}
::v-deep .el-carousel__indicators--outside button {
    background-color: #ff9900;
}
::v-deep .el-carousel__mask {
    background-color: #1a1818;
}
</style>
  