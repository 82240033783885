<template>
    <ek-panel title="政策找人">
        <div class="box">
            <!-- <div class="btn_box">
                <ek-single-btn :list="btnList"
                               :index="1"
                               @click="handleType" />
                <ek-segements :index="1"
                              :list="dateBtnList"
                              @click="handleDate" />
            </div>
            <div class="item">
                <ek-chart :options="optionsBar"
                          :animation="true"
                          :animationData="optionsBar.xAxis.data" />
            </div> -->
            <div class="table">
                <div class="table_header lucencys">
                    <span>政策标题</span>
                    <span>政策时间</span>
                    <span>匹配人数</span>
                    <span>政策覆盖率</span>
                </div>
                <div class="t-list">
                    <swiper ref="orderSwiper"
                            :options="swiperOptions">
                        <swiper-slide v-for="(items, index) in tableData"
                                      :key="index">
                            <div :class="[
                  'item',
                  index%2 == 1 ? 'lucency' : '',
                ]"
                                 v-for="(item, index) in items"
                                 :key="index">
                                <span>{{ item.policy_title }}</span>
                                <span>{{ item.policy_date.substr(0, 10) }}</span>
                                <span>{{ item.num }}</span>
                                <span>{{ item.policy_cover_rate+"%" }}</span>
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>
            </div>
        </div>
    </ek-panel>
</template>

<script>
import * as echarts from 'echarts'
import { requestPostBi } from '@/utils/requestMiddleground'
export default {
    data() {
        return {
            btnList: ['场所巡查分析', '上报事件分析'],
            dateBtnList: ['月', '年'],
            dateVal: '',
            optionsBar: {
                grid: {
                    left: '0%',
                    right: 0,
                    bottom: '3%',
                    top: '30%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    confine: true,
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    padding: 0,
                    formatter: (params) => {
                        // let { holidayVal, dateVal } = this.formData
                        // let unit = holidayVal ? '' : dateVal == 0 ? '时' : ''
                        let el = ''
                        params.map((item) => {
                            el = el.concat(
                                `<div class="text" style="font-size:18px;margin-top:5px;">
                        <div class="left">
                          <span>${item.marker}</span> 
                          <span class="t">${item.seriesName
                                }&nbsp&nbsp&nbsp</span> 
                        </div>
                        <div class="right">
                          <span class="v">${item.value +
                                (item.seriesName == '巡查数量'
                                    ? '次'
                                    : item.seriesName == '上报事件数量'
                                        ? '件'
                                        : '%')
                                }</span>  
                        </div>
                      </div>`
                            )
                        })
                        return `<div class="tooltip-box column">
                      <span class="b-t">${params[0].name}</span>
                      ${el}
                    </div>`
                    },
                },
                legend: {
                    left: '1%',
                    top: '5%',
                    itemWidth: 19,
                    itemHeight: 7,
                    textStyle: {
                        color: '#fff',
                        fontSize: 18,
                    },
                    data: [
                        {
                            name: '巡查数量',
                            icon: 'rect',
                            itemStyle: {
                                color: '#E9AD0B',
                            },
                        },

                        {
                            name: '达标率',
                            itemStyle: {
                                color: '#2ec0ea',
                            },
                        },
                    ],
                },
                xAxis: {
                    type: 'category',
                    axisTick: {
                        show: false,
                    },
                    // formatter: val => {
                    //     let { holidayVal, dateVal } = this.formData
                    //     let unit = holidayVal
                    //       ? '日'
                    //       : dateVal == 0
                    //       ? '时'
                    //       : dateVal == 1
                    //       ? '日'
                    //       : dateVal == 2
                    //       ? '月'
                    //       : ''
                    //     let date = this.dayjs(val).format(
                    //       holidayVal
                    //         ? 'DD'
                    //         : dateVal == 0
                    //         ? 'HH'
                    //         : dateVal == 1
                    //         ? 'DD'
                    //         : 'MM'
                    //     )
                    //     return date + unit
                    //   },
                    data: [],
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#A8B1AF',
                        fontSize: 16,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#306268',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    // 不显示刻度线
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        // 网格线为虚线
                        show: false,
                    },
                    axisLabel: {
                        color: '#A8B1AF',
                        fontSize: 16,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#2973AC',
                            type: [3, 5],
                        },
                    },
                },
                series: [
                    {
                        name: '巡查数量',
                        type: 'bar',
                        stack: 'add',
                        barWidth: '20%',
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: '#f99500',
                                },
                                {
                                    offset: 1,
                                    color: '#142D3F',
                                },
                            ]),
                        },
                        data: [],
                    },

                    {
                        name: '达标率',
                        type: 'line',
                        smooth: true,
                        symbol: 'none',
                        lineStyle: {
                            color: '#2ec0ea',
                        },
                        data: [],
                    },
                ],
            },
            dateSplice: '',
            typeValue: '',
            page: 1,
            activationsTotal: [],
            dateStr: '',
            arrDay: [
                { patrol: 45, month: '2023-06-11 00:06:00', complete_ratio: 90 },
                { patrol: 40, month: '2023-06-12 00:06:00', complete_ratio: 95 },
                { patrol: 60, month: '2023-06-13 00:06:00', complete_ratio: 90 },
                { patrol: 50, month: '2023-06-14 00:06:00', complete_ratio: 92 },
                { patrol: 55, month: '2023-06-15 00:06:00', complete_ratio: 95 },
                { patrol: 48, month: '2023-06-16 00:06:00', complete_ratio: 80 },
                { patrol: 45, month: '2023-06-17 00:06:00', complete_ratio: 82 },
                { patrol: 40, month: '2023-06-18 00:06:00', complete_ratio: 84 },
                { patrol: 60, month: '2023-06-19 00:06:00', complete_ratio: 90 },
                { patrol: 50, month: '2023-06-20 00:06:00', complete_ratio: 95 },
                { patrol: 55, month: '2023-06-21 00:06:00', complete_ratio: 92 },
                { patrol: 48, month: '2023-06-22 00:06:00', complete_ratio: 80 },
                { patrol: 45, month: '2023-06-23 00:06:00', complete_ratio: 90 },
                { patrol: 60, month: '2023-06-24 00:06:00', complete_ratio: 80 },
                { patrol: 35, month: '2023-06-25 00:06:00', complete_ratio: 85 },
                { patrol: 55, month: '2023-06-26 00:06:00', complete_ratio: 98 },
                { patrol: 48, month: '2023-06-27 00:06:00', complete_ratio: 70 },
                { patrol: 45, month: '2023-06-28 00:06:00', complete_ratio: 80 },
                { patrol: 40, month: '2023-06-29 00:06:00', complete_ratio: 90 },
                { patrol: 60, month: '2023-06-30 00:06:00', complete_ratio: 95 },
                { patrol: 50, month: '2023-06-31 00:06:00', complete_ratio: 70 },
            ],
            arrYear: [
                { patrol: 60, month: '1', complete_ratio: 70 },
                { patrol: 50, month: '2', complete_ratio: 72 },
                { patrol: 55, month: '3', complete_ratio: 75 },
                { patrol: 48, month: '4', complete_ratio: 80 },
                { patrol: 45, month: '5', complete_ratio: 90 },
                { patrol: 40, month: '6', complete_ratio: 85 },
                { patrol: 60, month: '7', complete_ratio: 70 },
                { patrol: 50, month: '8', complete_ratio: 72 },
                { patrol: 55, month: '9', complete_ratio: 75 },
                { patrol: 48, month: '10', complete_ratio: 80 },
                { patrol: 45, month: '11', complete_ratio: 90 },
                { patrol: 40, month: '12', complete_ratio: 95 },
                { patrol: 60, month: '13', complete_ratio: 90 },
                { patrol: 50, month: '14', complete_ratio: 92 },
                { patrol: 55, month: '15', complete_ratio: 95 },
                { patrol: 48, month: '16', complete_ratio: 80 },
                { patrol: 45, month: '17', complete_ratio: 82 },
                { patrol: 40, month: '18', complete_ratio: 84 },
                { patrol: 60, month: '19', complete_ratio: 90 },
                { patrol: 50, month: '20', complete_ratio: 95 },
                { patrol: 55, month: '21', complete_ratio: 92 },
                { patrol: 48, month: '22', complete_ratio: 80 },
                { patrol: 45, month: '23', complete_ratio: 90 },
                { patrol: 60, month: '24', complete_ratio: 80 },
                { patrol: 35, month: '25', complete_ratio: 85 },
                { patrol: 55, month: '26', complete_ratio: 98 },
                { patrol: 48, month: '27', complete_ratio: 70 },
                { patrol: 45, month: '28', complete_ratio: 80 },
                { patrol: 40, month: '29', complete_ratio: 90 },
                { patrol: 60, month: '30', complete_ratio: 95 },
                { patrol: 50, month: '31', complete_ratio: 70 },
            ],
            swiperOptions: {
                direction: 'vertical',
                autoplay: {
                    delay: 6000,
                    disableOnInteraction: false,
                },
                speed: 900,
                loop: false,
            },
            tableData: []
        }
    },
    mounted() {
        this.typeValue = 0
        this.getYearBarList()
        // this.getBarListDay()
        this.handleDate(1)
        this.getList()
        // this.handleType(0)
    },
    methods: {
        async getBarListDay() {
            const resArr = await Promise.all([
                requestPostBi('', this.getParams()),
                requestPostBi('', this.getParams()),
                requestPostBi('', this.getParams()),
            ])
            this.page = 1
            if (this.typeValue == 0) {
                this.activationsTotal = resArr.reduce((result, obj) => {
                    const activations = obj['Activation:up[]'] || []
                    const totals = activations.map((act) => act['Activation:total'])
                    return result.concat(totals)
                }, [])
            }


            this.optionsBar.xAxis.data = this.activationsTotal
                .map((item) => item.date_month.substr(8, 2) + this.dateStr)
                .splice(0, this.dateSplice)
            this.optionsBar.series[0].data = this.activationsTotal
                .map((item) => item.party_num)
                .splice(0, this.dateSplice)
            this.optionsBar.series[1].data = this.activationsTotal
                .map((item) => item.merchant_num)
                .splice(0, this.dateSplice)

            // 输出所有的 Activation:total 属性对应的值
            // this.dataArr = resArr.map((res) => res.data)
        },

        getParams() {
            return {
                'Activation:up[]': {
                    page: this.page++,
                    count: '10',
                    'Activation:total': {
                        '@column':
                            'date_format(activation_time,%Y-%m-%d %H:%m:%S):date_month;merchant_num;party_num',
                        'activation_time%': '2023-06-01,2023-06-30',
                        '@order': 'activation_time+',
                    },
                },
            }
        },
        getYearBarList() {
            let than = this
            let par = {
                'PlaceResource:Down[]': {
                    PlaceResource: {
                        '@column':
                            'month;sum(inspect_num):patrol;sum(complete_ratio):complete_ratio',
                        'month{}': '<10',
                        '@group': 'month',
                        '@order': 'month+',
                    },
                },
                'PlaceResource:Up[]': {
                    PlaceResource: {
                        '@column':
                            'month;sum(inspect_num):patrol;sum(complete_ratio):complete_ratio',
                        'month{}': '>=10',
                        '@group': 'month',
                        '@order': 'month+',
                    },
                },
            }
            requestPostBi('', par)
                .then((res) => {
                    let arr = []
                    arr = [...res['PlaceResource:Down[]'], ...res['PlaceResource:Up[]']]
                    this.optionsBar.xAxis.data = arr
                        .map((item) => item.month + than.dateStr)
                        .splice(0, than.dateSplice)
                    this.optionsBar.series[0].data = arr
                        .map((item) => item.patrol)
                        .splice(0, than.dateSplice)
                    this.optionsBar.series[1].data = arr
                        .map((item) => item.complete_ratio)
                        .splice(0, than.dateSplice)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getYearBarList1() {
            this.optionsBar.xAxis.data = this.arrYear
                .map((item) => item.month + this.dateStr)
                .splice(0, this.dateSplice)
            this.optionsBar.series[0].data = this.arrYear
                .map((item) => item.patrol)
                .splice(0, this.dateSplice)
            this.optionsBar.series[1].data = this.arrYear
                .map((item) => item.complete_ratio)
                .splice(0, this.dateSplice)
        },
        getBarListDay1() {
            this.optionsBar.xAxis.data = this.arrDay
                .map((item) => item.month.substr(8, 2) + '日')
                .splice(0, this.dateSplice)
            this.optionsBar.series[0].data = this.arrDay
                .map((item) => item.patrol)
                .splice(0, this.dateSplice)
            this.optionsBar.series[1].data = this.arrDay
                .map((item) => item.complete_ratio)
                .splice(0, this.dateSplice)
        },
        handleType(value) {
            this.typeValue = value
            this.dateStr = this.dateVal == 0 ? '日' : '月'
            if (value == 0) {
                this.optionsBar.series[0].name = '巡查数量'
                this.optionsBar.series[1].name = '达标率'
                this.optionsBar.legend.data[0].name = '巡查数量'
                this.optionsBar.legend.data[1].name = '达标率'

                if (this.dateVal == 0) {
                    this.getBarListDay()
                } else {
                    this.getYearBarList()

                }
            } else {
                this.optionsBar.series[0].name = '上报事件数量'
                this.optionsBar.series[1].name = '完成率'
                this.optionsBar.legend.data[0].name = '上报事件数量'
                this.optionsBar.legend.data[1].name = '完成率'
                if (this.dateVal == 0) {
                    this.getBarListDay1()
                } else {
                    this.getYearBarList1()
                }
            }
        },
        handleDate(value) {
            console.log(this.typeValue);
            this.dateStr = value == 1 ? '月' : '日'
            this.dateVal = value
            let date = new Date()
            let month = date.getMonth() + 1
            let day = date.getDate() - 10
            this.dateSplice = value == 0 ? day : month
            if (value == 1) {
                if (this.typeValue == 1) {
                    this.getYearBarList1()
                } else {
                    this.getYearBarList()
                }
            } else {
                if (this.typeValue == 0) {
                    this.getBarListDay()
                } else {
                    this.getBarListDay1()
                }
            }
        },
        getList() {
            let par = {
                "ServicePolicyFinders[]": {
                    "ServicePolicyFinder": {
                        "@column": "id,policy_title,policy_date,num,policy_cover_rate"
                    },
                    "count": 0,
                    "query": 2
                }
            }
            requestPostBi('', par)
                .then((res) => {
                    if (res.code == 200) {
                        let tempList = res['ServicePolicyFinders[]'].map(m => {
                            return {
                                id: m.ServicePolicyFinder.id,
                                num: m.ServicePolicyFinder.num,
                                policy_cover_rate: m.ServicePolicyFinder.policy_cover_rate,
                                policy_date: m.ServicePolicyFinder.policy_date.split("T")[0],
                                policy_title: m.ServicePolicyFinder.policy_title,
                            }
                        })
                        this.tableData = this.groupList(tempList, 8)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    components: {},
}
</script>

<style scoped lang="scss">
.box {
    height: 420px - 30px;
    padding: 15px 20px;
    .btn_box {
        @include flex();
        justify-content: space-between;
    }
    .item {
        height: 360px;
        width: 868px;
    }
    .table {
        @include flex(column);
        margin-top: 20px;
        .lucency {
            background: rgba(32, 39, 56, 0.5);
        }
        .lucencys {
            background: rgba(32, 39, 56, 0.8);
        }
        .table_header {
            @include flex();
            justify-content: space-around;
            text-align: center;
            span {
                height: 50px;
                line-height: 50px;
            }
            :nth-child(1) {
                flex: 1;
            }
            :nth-child(1) {
                width: 100px;
            }
            :nth-child(2) {
                width: 200px;
            }
            :nth-child(3) {
                width: 220px;
            }
            :nth-child(4) {
                width: 210px;
            }
        }

        .t-list {
            @include flex(column);
            flex: 1;
            .swiper-container {
                height: 310px;
                width: 100%;
            }
            .item {
                @include flex();
                align-items: center;
                height: 50px;
                justify-content: space-around;
                background: rgba($color: #202738, $alpha: 0.5);
                cursor: pointer;
                span {
                    text-align: center;
                }
                > :nth-child(2) {
                    width: 200px;
                }
                > :nth-child(1) {
                    flex: 1;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > :nth-child(1) {
                    width: 100px;
                }
                > :nth-child(3) {
                    width: 220px;
                }
                > :nth-child(4) {
                    width: 210px;
                }
            }
        }
    }
    .lucency {
        background: rgba(32, 39, 56, 0.8) !important;
    }
}
</style>
