<template>
  <div class="container">
    <ek-legend />
  </div>
</template>
  
  <script>
export default {
  components: {},
}
</script>
  
  <style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: flex-end;
  z-index: 100000;
  height: 100%;
}
</style>
  