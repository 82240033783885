<template>
  <div class="container">
    <r-1></r-1>
  </div>
</template>

<script>
import r1 from './components/r1'
export default {
  components: { r1 },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  justify-content: space-between;
}
</style>
