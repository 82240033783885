<template>
  <div class="container">
    <l-1></l-1>
    <l-2></l-2>
  </div>
</template>

<script>
import L1 from './components/l1.vue'
import L2 from './components/l2.vue'
export default {
  components: { L1, L2 },
  data() {
    return {}
  },
}
</script>

<style scoped lang="scss">
.container {
  @include flex(column);
  > :nth-child(2) {
    margin-top: 25px;
  }
}
</style>
